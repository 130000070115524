.tile {
  width: 310px;
  height: 290px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  background: rgba(0, 35, 80, 0.668);
  padding: 30px 15px;
}

.tile > .tile__img {
  width: 40%;
  object-fit: contain;
  margin-bottom: 10px;
}

.tile > .tile__title {
  font-size: 1.9rem;
  margin-bottom: 8px;
  font-weight: 600;
  color: rgb(255, 255, 255);
}

.tile > .tile__summary {
  color: rgb(61, 220, 255);
  text-align: center;
  font-size: 1.6rem;
  font-weight: 300;
}

@media screen and (max-width: 830px) {
  .tile {
    height: 240px;
  }

  .tile > .tile__title {
    font-size: 1.65rem;
  }

  .tile > .tile__summary {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .tile {
    width: 45%;
  }
}

@media screen and (max-width: 550px) {
  .tile {
    width: 80%;
    height: auto;
  }
}

@media screen and (max-width: 320px) {
  .tile {
    width: 100%;
  }
}
