.app {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

.fa-html5 {
  color: rgb(170, 44, 13);
}

.fa-js-square {
  color: rgb(243, 207, 1);
}

.fa-sass {
  color: rgb(199, 100, 148);
}

.fa-git-alt {
  color: rgb(232, 77, 49);
}

.fa-node {
  color: rgb(30, 119, 18);
}

.fa-react {
  color: rgb(94, 211, 243);
}

.fa-css3 {
  color: rgb(37, 75, 221);
}

.app > * {
  box-sizing: border-box;
}

div {
  transition: all ease-in-out 0.3s;
}

/*  Hero Section */
.app > .app__hero {
  width: 100%;
  height: 100vh;
  display: grid;
  padding-top: 10vh;
  place-items: center;
  background-color: #011735;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg %3E%3Ccircle fill='%23011735' cx='400' cy='400' r='600'/%3E%3Ccircle fill='%23032540' cx='400' cy='400' r='500'/%3E%3Ccircle fill='%230e3249' cx='400' cy='400' r='400'/%3E%3Ccircle fill='%231d4052' cx='400' cy='400' r='300'/%3E%3Ccircle fill='%232d4e5a' cx='400' cy='400' r='200'/%3E%3Ccircle fill='%233f5c63' cx='400' cy='400' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.app > .app__hero > .app__heroIntro {
  width: fit-content;
  color: #fff;
  text-align: center;
}

.app > .app__hero > .app__heroIntro > .app__heroIntroTitle {
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;
}

.app > .app__hero > .app__heroIntro > .app__heroIntroTitle > .name {
  font-weight: 900;
  color: rgb(241, 143, 1);
}

.app > .app__hero > .app__heroIntro > .app__heroIntroSubtitle {
  font-weight: 200;
  font-size: 3rem;
  width: 70%;
  margin: auto;
  margin-bottom: 20px;
}

.app > .app__hero > .app__heroIntro > .app__heroIntroBtn {
  transition: all ease-in-out 0.4s;
  font-size: 1.8rem;
  text-decoration: none;
  padding: 10px 30px;
  font-weight: 600;
  border: 1.5px solid rgb(241, 143, 1);
  color: rgb(241, 143, 1);
  background: rgb(1, 33, 78);
}

.app > .app__hero > .app__heroIntro > .app__heroIntroBtn:hover {
  border: 1.5px solid rgb(4, 139, 168);
  color: rgb(4, 139, 168);
}

/*  About Section */
.app > .app__about {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 80px 10%;
}

.app > .app__about > .app__aboutTitle {
  width: fit-content;
  margin: auto;
  margin-bottom: 40px;
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 900;
  color: rgb(185, 111, 0);
  text-decoration: overline;
}

.app > .app__about > .app__aboutBody {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.app > .app__about > .app__aboutBody > .app__aboutBodyBio {
  width: 50%;
  font-size: 1.65rem;
  font-weight: 300;
  text-align: center;
  line-height: 2.3rem;
}

.app > .app__about > .app__aboutBody > .app__aboutBodyBio > .bio__title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: rgb(1, 33, 78);
  font-weight: 600;
}

.app > .app__about > .app__aboutBody > .app__aboutBodyBio > .bio__description {
  margin-bottom: 10px;
  font-weight: 500;
}

.app
  > .app__about
  > .app__aboutBody
  > .app__aboutBodyBio
  > .bio__description
  a {
  color: rgb(180, 109, 3);
  font-weight: 600;
}

.app > .app__about > .app__aboutBody > .app__aboutBodyBio > .bio__resume {
  margin: 20px auto;
  margin-bottom: 0;
  text-align: center;
  font-size: 1.7rem;
  color: rgb(1, 23, 53);
  font-weight: 400;
  font-style: italic;
  text-decoration: underline;
}

.app
  > .app__about
  > .app__aboutBody
  > .app__aboutBodyBio
  > .bio__resume:visited {
  color: rgb(1, 23, 53);
}

.app > .app__about > .app__aboutBody > .app__aboutMe {
  width: 30%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: grid;
  place-items: center;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), -1px -1px 2px rgb(0, 35, 80, 0.2);
}

.app > .app__about > .app__aboutBody > .app__aboutMe > .app__aboutMeImg {
  width: 100%;
  object-fit: contain;
}

.app > .app__about > .app__aboutFooter {
  padding-top: 20px;
}

.app > .app__about > .app__aboutFooter > h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: rgb(1, 23, 53);
  text-align: center;
  margin-bottom: 15px;
}

.app > .app__about > .app__aboutFooter > .skills {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.app > .app__about > .app__aboutFooter > .skills > * {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 800;
  color: rgb(2, 177, 216);
}

/* Projects section */
.app > .app__projects {
  box-sizing: border-box;
  padding: 80px 5%;
  background: rgb(13, 132, 158);
  /* background-size: cover; */
  /* background-attachment: fixed; */
}

.app > .app__projects > .app__projectsTitle {
  width: fit-content;
  margin: auto;
  margin-bottom: 40px;
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 900;
  color: rgb(0, 35, 80);
  text-decoration: overline;
}

.app > .app__projects > .app__projectsAttribution {
  display: none;
}

.app > .app__projects > .app__projectsTiles {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 40px;
}

.app > .app__projects > .app__projectsWork {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.app > .app__projects > .app__projectsWork > .project {
  width: 48%;
  padding: 15px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2),
    -1px -1px 2px rgb(240, 240, 240, 0.5);
}

.app > .app__projects > .app__projectsWork > .project > .project__title {
  font-size: 1.7rem;
  font-weight: 800;
  color: rgb(0, 35, 80);
}

.app > .app__projects > .app__projectsWork > .project > .project__img {
  width: 100%;
  object-fit: contain;
}

.app > .app__projects > .app__projectsWork > .project > .project__stack {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  margin: 5px auto;
}

.app > .app__projects > .app__projectsWork > .project > .projectDescription {
  flex: 1;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 300;
}

.app > .app__projects > .app__projectsWork > .project > .project__btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.app
  > .app__projects
  > .app__projectsWork
  > .project
  > .project__btns
  > .project__btn {
  transition: all ease-in-out 0.4s;
  font-size: 1.4rem;
  text-decoration: none;
  padding: 10px 30px;
  margin-left: 20px;
  font-weight: 600;
  border: 1.5px solid rgb(241, 143, 1);
  color: rgb(241, 143, 1);
  background: rgb(1, 33, 78);
}

.app
  > .app__projects
  > .app__projectsWork
  > .project
  > .project__btns
  > :first-child {
  margin-left: 0;
}

.app
  > .app__projects
  > .app__projectsWork
  > .project
  > .project__btns
  > .project__btn:hover {
  border: 1.5px solid rgb(4, 139, 168);
  color: rgb(4, 139, 168);
}

/* Contact Section Here */
.app > .app__contact {
  padding: 80px 10%;
  background: #2e4057;
}

.app > .app__contact > .app__contactTitle {
  width: fit-content;
  margin: auto;
  margin-bottom: 40px;
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 900;
  color: rgb(0, 208, 255);
  text-decoration: overline;
}

.app > .app__contact > .app__contactSubtitle {
  text-align: center;
  font-size: 2.2rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: rgb(245, 165, 46);
}

.app > .app__contact > .app__contactForm {
  width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app > .app__contact > .app__contactForm > * {
  height: 35px;
  font-size: 1.6rem;
  padding: 0 8px;
  width: 100%;
  margin-bottom: 10px;
  background: rgb(1, 33, 78);
  border: none;
  color: rgb(241, 143, 1);
  outline: none;
  font-weight: 300;
  font-family: "Poppins", cursive;
  box-sizing: border-box;
}

.app > .app__contact > .app__contactForm > .app__contactFormInput.error {
  border: 1px solid red;
  color: red !important;
  font-weight: bold;
}

.app > .app__contact > .app__contactForm > .app__contactFormInput.success {
  border: 1.5px solid rgb(0, 189, 0);
}

.app > .app__contact > .app__contactForm > *:focus {
  background: rgb(1, 33, 78);
  color: rgb(241, 143, 1);
}

.app > .app__contact > .app__contactForm > textarea {
  padding: 10px;
  font-size: 1.6rem;
  height: calc(35px * 4);
  resize: none;
}

.app > .app__contact > .app__contactForm > textarea::-webkit-scrollbar {
  background: transparent;
  width: 8px;
  cursor: pointer;
}

.app > .app__contact > .app__contactForm > textarea::-webkit-scrollbar-track {
  background: rgb(3, 109, 133);
}

.app > .app__contact > .app__contactForm > textarea::-webkit-scrollbar-thumb {
  background: rgb(241, 143, 1);
  width: 13px;
}

.app > .app__contact > .app__contactForm > .app__contactFormBtn {
  width: 30%;
  margin-left: auto;
  cursor: pointer;
  border: 1.5px solid rgb(241, 143, 1);
  transition: all ease-in-out 0.2s;
}

.app > .app__contact > .app__contactForm > .app__contactFormBtn.sent {
  border-color: rgb(0, 189, 0);
}

.app > .app__contact > .app__contactForm > .app__contactFormBtn > .fas {
  color: rgb(0, 189, 0);
  font-size: 2rem;
}

.app > .app__contact > .app__contactForm > .app__contactFormBtn:hover {
  border-color: rgb(4, 139, 168);
  color: rgb(4, 139, 168);
}

/* Footer Section Here */
.app > .app__footer {
  padding: 80px 10%;
  padding-bottom: 30px;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.2);
}

.app > .app__footer > .app__footerContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.app > .app__footer > .app__footerContainer > .iconContainer {
  padding: 10px 10px;
  background: rgb(1, 23, 53);
  color: rgb(4, 139, 168);
  box-shadow: 1px 1px 1.7 rgba(0, 0, 0, 0.2),
    -1px -1px 1.7 rgba(240, 240, 240, 0.4);
}

.app > .app__footer > .app__footerContainer > .iconContainer > a {
  color: rgb(4, 139, 168);
}

.app > .app__footer > .app__footerContainer > .iconContainer > a:hover {
  color: rgb(241, 143, 1);
}

.app > .app__footer > .app__footerDisclaimer {
  font-size: 1.7rem;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  color: rgb(165, 100, 1);
}

@media screen and (max-width: 960px) {
  /* Hero Section */
  .app > .app__hero > .app__heroIntro > .app__heroIntroTitle {
    font-size: 3.2rem;
  }

  .app > .app__hero > .app__heroIntro > .app__heroIntroSubtitle {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }

  /* About section */
  .app > .app__about {
    padding-left: 8%;
    padding-right: 8%;
  }

  .app > .app__about > .app__aboutBody > .app__aboutBodyBio {
    width: 55%;
  }

  .app > .app__about > .app__aboutBody > .app__aboutBodyBio > .bio__title {
    font-size: 2.2rem;
  }

  .app
    > .app__about
    > .app__aboutBody
    > .app__aboutBodyBio
    > .bio__description {
    font-size: 1.45rem;
  }

  .app > .app__about > .app__aboutBody > .app__aboutBodySkills {
    width: 40%;
  }

  /* Project Section */
  .app > .app__projects > .app__projectsWork > .project {
    padding: 10px;
  }

  .app > .app__projects > .app__projectsWork > .project > .project__stack * {
    font-size: 2.3rem;
    width: 33px;
  }

  .app > .app__projects > .app__projectsWork > .project > .projectDescription {
    font-size: 1.45rem;
  }
}

@media screen and (max-width: 768px) {
  /* About Section */
  .app > .app__about > .app__aboutBody {
    flex-direction: column;
  }

  .app > .app__about > .app__aboutBody > .app__aboutBodyBio {
    width: 90%;
  }

  .app > .app__about > .app__aboutBody > .app__aboutBodyBio > .bio__title {
    font-size: 2.4rem;
  }

  .app
    > .app__about
    > .app__aboutBody
    > .app__aboutBodyBio
    > .bio__description {
    font-size: 1.8rem;
  }

  .app > .app__about > .app__aboutBody > .app__aboutMe {
    margin: 30px 0;
    width: 60%;
  }

  /* Projects Section Here */
  .app > .app__projects > .app__projectsTiles {
    flex-wrap: wrap;
    gap: 30px;
  }

  .app > .app__projects > .app__projectsWork {
    flex-direction: column;
    align-items: center;
  }

  .app > .app__projects > .app__projectsWork > .project {
    width: 100%;
    margin-bottom: 30px;
    padding: 15px 10px;
  }

  .app > .app__projects > .app__projectsWork > :last-child {
    margin-bottom: 0;
  }

  .app > .app__projects > .app__projectsWork > .project > .projectDescription {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 620px) {
  .app > .app__contact > .app__contactSubtitle {
    font-size: 1.9rem;
  }

  .app > .app__contact > .app__contactForm {
    width: 100%;
  }
}

@media screen and (max-width: 530px) {
  .app > .app__hero > .app__heroIntro > .app__heroIntroTitle {
    font-size: 2.8rem;
  }

  .app > .app__hero > .app__heroIntro > .app__heroIntroSubtitle {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 460px) {
  .app > .app__about {
    padding-left: 5%;
    padding-right: 5%;
  }

  .app > .app__about > .app__aboutBody > .app__aboutBodyBio > .bio__title {
    font-size: 1.9rem;
  }

  .app
    > .app__about
    > .app__aboutBody
    > .app__aboutBodyBio
    > .bio__description {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 430px) {
  .app > .app__hero > .app__heroIntro > .app__heroIntroTitle {
    font-size: 1.85rem;
  }

  .app > .app__hero > .app__heroIntro > .app__heroIntroSubtitle {
    font-size: 1.4rem;
  }

  .app
    > .app__projects
    > .app__projectsWork
    > .project
    > .project__btns
    > .project__btn {
    padding: 8px 10px;
  }
}
